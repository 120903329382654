import { Link, graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/Layout'
import * as styles from "../../styles/projects.module.css"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"


export default function Projects({ data }) {
  console.log(data.projects.nodes)
  // console.log(data)

  const projects = data.projects.nodes
  const contact = data.contact.siteMetadata.contact
  console.log(contact)

  return (
    <Layout>
      <div className={styles.portfolio}>
        <h2>Projects</h2>
        <h3>Those projects are available</h3>

        <div className={styles.projects}>
          {projects.map(project =>(
            <Link to={"/projects/" + project.frontmatter.slug} key={project.id}>
              <div>
                <div className="project_photo">
                  <GatsbyImage image={project.frontmatter.thumb.childImageSharp.gatsbyImageData} />
                </div>
                <h3>{ project.frontmatter.title }</h3>
                <p>{ project.frontmatter.stack }</p>
              </div>
            </Link>
          ))}
        </div>
        <p>Connect me with Email at {contact} for a quote!</p>
      </div>
    </Layout>
  )
}



// export page query
export const query = graphql`
  query ProjectsPage {
    projects: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          stack
          title
          slug
          # date

          thumb {
            childImageSharp {
              # fluid {
              #   src
              #   ...GatsbyImageSharpFluid
              # }
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }

        }
        id
      }
    }
    contact: site {
      siteMetadata {
        contact
      }
    }
  }
`